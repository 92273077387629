@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
}

.card-element-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds space between the elements */
}

.card-element-container label {
  margin-bottom: 5px;
  font-weight: bold;
}

.card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}